body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

.slide-down {
  animation-name: slideDown;
  animation-duration: 0.5s;
}

.slide-up {
  animation-name: slideUp;
  animation-duration: 0.5s;
}

.VenueSignupAlert {
  /* add your styles here */
}
